/** @jsx jsx */
import { graphql } from "gatsby";
import { Attributes } from "gatsby-theme-attribute";
import React, { memo } from "react";
import { jsx } from "theme-ui";

import { Maybe, PageQuery } from "../../../../__generated__/gatsby.types";
import WidgetWrapper from "../../WidgetWrapper";
import type { WidgetProps, WidgetUnion } from "../types";

interface Props extends WidgetProps {
  widget: Extract<WidgetUnion, { __typename: "WidgetAttributes" }>;
  data: PageQuery["theater"];
}

const AttributesWidget: React.FC<Props> = ({
  isFirst,
  isNested,
  isLast,
  widget,
  prevWidget,
  nextWidget,
  containerSize,
  data,
}) => {
  let visibleTags: readonly Maybe<string>[] =
    widget.attributesShape?.tags || [];

  let screensTags: readonly Maybe<string>[] = [];

  // Aggregate screen attributes in one array.
  data?.screens?.forEach((screen) => {
    screensTags = screensTags.concat(
      ...(screen?.flags?.accessibility || []),
      ...(screen?.flags?.comfort || []),
      ...(screen?.flags?.experience || []),
      ...(screen?.flags?.food || []),
      ...(screen?.flags?.picture || []),
      ...(screen?.flags?.restriction || []),
      ...(screen?.flags?.service || []),
      ...(screen?.flags?.sound || [])
    );
  });

  /**
   * Override and filter the widget tags if theater data has been passed.
   * We want to display the screen tags matching the widget selection
   */
  if (data) {
    visibleTags = screensTags.filter((tag) => {
      if (!widget.attributesShape?.tags?.length) {
        return true;
      }
      return tag && widget.attributesShape.tags.includes(tag);
    });
  }

  return (
    <WidgetWrapper
      isFirst={isFirst}
      isLast={isLast}
      isNested={isNested}
      containerSize={containerSize}
      widget={widget}
      prevWidget={prevWidget}
      nextWidget={nextWidget}
    >
      <Attributes
        tags={[...visibleTags]}
        centered
        size={"large"}
        forcedTheaterId={data?.id}
        wrappedItems={!!data}
      />
    </WidgetWrapper>
  );
};

export const query = graphql`
  fragment WidgetAttributes on WidgetAttributes {
    id
    attributesShape {
      tags
    }
    __typename
  }
`;

export default memo(AttributesWidget);
